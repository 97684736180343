<template>
  <div>
    <b-row>
      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('admin.club_detail.nextore-config.customer-number') }}
        </label>
        <d-text-field
          v-model="nextoreAccount.reference"
          size="sm"
          class-name="background-light-blue-inputs"
        />
      </b-col>

      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('admin.club_detail.nextore-config.identifier') }}
        </label>
        <d-text-field
          v-model="nextoreAccount.username"
          size="sm"
          class-name="background-light-blue-inputs"
        />
      </b-col>

      <b-col cols="4" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('admin.club_detail.nextore-config.password') }}
        </label>
        <d-text-field
          v-model="nextoreAccount.password"
          type="password"
          size="sm"
          class-name="background-light-blue-inputs"
        />
      </b-col>
      <b-col cols="8" class="mt-2 mb-2">
        <label class="field-name-class">
          {{ $t('admin.club_detail.nextore-config.token') }}
        </label>
        <d-text-field
          v-model="nextoreAccount.apiToken"
          size="sm"
          class-name="background-light-blue-inputs"
        />
      </b-col>
    </b-row>
    <b-row align="right">
      <b-col
        align="right"
        cols="3"
        class="mt-2 mb-2"
      >
        <div
          class="d-btn-small btn d-btn-primary btn-full-width"
          @click="onValidate"
        >
          {{ $t('general.actions.validate') }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import NextoreAccount from "@/classes/NextoreAccount";
import {postNextoreAccount} from "@api/services/nextore/account.api";
import {DANGER, SUCCESS} from "@plugins/flash";

export default {
  data: ()=> ({
    nextoreAccount: new NextoreAccount()
  }),
  props: {
    club: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    onValidate() {
      this.nextoreAccount.club = this.club['@id'];

      postNextoreAccount(this.nextoreAccount)
        .then(() => {
          this.$flash(null, this.$t('admin.club_detail.nextore-config.caisse-configured'), 5000, SUCCESS);
        })
        .catch(() => {
          this.$flash(null, this.$t('admin.club_detail.nextore-config.error-caisse'), 5000, DANGER);
        })
      ;
    }
  },
}
</script>
